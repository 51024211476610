import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: row;
    text-align: center;
    color: white;
    background-color: var(--ZeusBrown);
`;

const FooterItem = styled.div`
    &.fLeft, &.fRight {
        position: relative;
        text-align: center;
        align-content: center;
        width: 25%;
    }
    &.fCenter {
        display: flex;
        flex-direction: column;
        border-color: white;
        border-right: 1px solid;
        border-left: 1px solid;
        margin-top: auto;
        margin-bottom: auto;
        width: 50%;
    }
`;

const FTop = styled.div`
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 10%;
    padding-right: 10%;
`;

const FBottom = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
`;

const NoBullets = styled.ul`
    list-style-type: none;
    font-size: 12px;
    margin-bottom: 0;
    padding-left: 0;
`;

const Logo = styled.li`
    align-content: center;
    margin-left: auto;
    margin-right: auto;
`;

const SocialIcon = styled.img`
    height: 30px;
    width: 26px;
    margin: auto;
`;

const Garentee = styled.b`
    font-size: 18px;
`;

const Terms = styled.p`
    font-size: 10px;
    text-align: center;
`;

const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    &:hover {
        color: var(--CadmiumOrange);
        text-decoration: none;
    }
`;

export const Footer = () => {
    return (
        <FooterContainer>
            <FooterItem className="fLeft">
                <NoBullets>
                    <li><StyledLink to="/locations">Locations</StyledLink></li>
                    <li><StyledLink to="https://paylink.paytrace.com/?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.0nTSF5WsS6qMe9HI2_SSOpVCb00kpNLx.KrMaKbCPnH8ff53S.-XMj0ilD1gCU7_8nIwGF6LoJLTWxXQYO_AdIXTSQ2MNIPW1gAYjVCi_foEHbaKO4iIYasA-bx49eW5fjSCqDhBrgj3sSjP7zsj9XWKSQ-rw35AyoDEfa9TCGL3nGQlEZWgEh_8hEeAb5SWH21HTqOCq9eAhGoUNmYV6GiZ8tezK21cihQRZJlqmLBefn3qCsq3IyfSJz8V3Mn1Ma14KgS7ptvGSDy8Abg4al9l91F9YIMhYzLisHWwoO8nyob-rqs1blzI_neMaVti-xaVHve0j9yXmxbFenkdIAZA.uhgfN4EQ9hRRlaC8Wk7lyA&amount=&invoice=" target='_blank' referrerPolicy='noreferrer'>Online Pay Portal</StyledLink></li>
                    <li><StyledLink to="/about-us">About Us</StyledLink></li>
                </NoBullets>
            </FooterItem>
            <FooterItem className="fCenter">
                <FTop>
                    <Garentee>Analytical Excellence Since 1952</Garentee>
                </FTop>
                <br />
                <FBottom>
                    <Terms><StyledLink to="/privacy-policy">Privacy Policy</StyledLink> | <StyledLink to="/terms-and-conditions">Terms and Conditions</StyledLink></Terms>
                </FBottom>
            </FooterItem>
            <FooterItem className="fRight">
                <NoBullets>
                    <Logo>
                        <a href="https://www.facebook.com/profile.php?id=100057349304692" target="_blank" rel="noreferrer"><SocialIcon src="logos/Facebook.png" alt="facebook" /></a>
                        <a href="https://www.linkedin.com/company/energy-laboratories/mycompany/" target="_blank" rel="noreferrer"><SocialIcon src="logos/Linkedin.png" alt="LinkedIn" /></a>
                    </Logo>
                    <li><StyledLink to="https://www.mybensite.com/energylab/" target='_blank' referrerPolicy='noreferrer'>For Employees</StyledLink></li>
                    <li><StyledLink to="/faq">FAQ</StyledLink></li>
                    <li><StyledLink to="/helpful-links">Helpful Links</StyledLink></li>
                </NoBullets>
            </FooterItem>
        </FooterContainer>
    );
};
