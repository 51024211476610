import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as signalR from "@microsoft/signalr";
import TextareaAutosize from 'react-textarea-autosize';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 10;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background-color: white;
    width: 350px;
    height: ${(props) => (props.isChatOpen ? '600px' : '75px')};
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px 1px 15px -3px black;
    transition: 0.4s ease-in-out;
`;

const TopBanner = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -5px 10px 5px black;
    background-color: #292220;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const Icon = styled.div`
    width: 56px;
    height: 56px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./logoSmall_TEMPORARY_AND_LOW_QUALITY.png");
`;

const PassiveUsersCount = styled.div`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`;

const CloseIcon = styled.div`
    width: 56px;
    height: 56px;
    display: inline-block;
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./logos/down.png");
    filter: invert(1);
    cursor: pointer;
    transform: rotate(${(props) => (props.isChatOpen ? "0deg" : "180deg")});
    transition: 0.3s;
`;

const MessageBox = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
`;

const MessageHeader = styled.p`
    text-align: right;
`

const MessageBody = styled.div`
    background-color: ${(props) => (!props.fromAgent ? "#cbcbe4" : "#f58730")};
    color: ${(props) => (!props.fromAgent ? "black" : "white")};
`

const Message = styled.div`
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    max-width: 70%;
    align-self: ${(props) => (!props.fromAgent ? "flex-start" : "flex-end")};
    border-bottom-left-radius: ${(props) => (!props.fromAgent ? "0px" : "5px")};
    border-bottom-right-radius: ${(props) => (!props.fromAgent ? "5px" : "0px")};
    z-index: -1;
    overflow-wrap: break-word;
`;

const ResizingTextArea = styled(TextareaAutosize)`
    flex-grow: 1;
    resize: none;
    border-radius: 5px;
    padding: 0.5rem;
`;

const SystemMessage = styled.div`
    color: grey;
    width: 100%;
    text-align: center;
`;


const BottomBar = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #292220;
    gap: 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 5px 10px 5px black;
`;

const SubmitButton = styled.button`
    border-radius: 5px;
    background-color: ${(props) => (props.disabled ? "grey" : "#f58730")};
    transition: 0.3s;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding: 0.5rem;
    border: none;
`;



const ChatWindow = () => {
    const [chat, setChat] = useState({ messages: [] });
    const [messageField, setMessageField] = useState("");
    const messageBoxRef = useRef(null);

    const [connection, setConnection] = useState(null);
    
    const [isChatOpen, setIsChatOpen] = useState(false);

    const [chatCustomer, setChatCustomer] = useState();
    
    const [agentIsAvailable, setAgentIsAvailable] = useState(false);

    const [mainWindowScrolledToBottom, setMainWindowScrolledToBottom] = useState(false);
    
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_APILink + "/chat")
            .build();

        const handleScroll = () => {
            const bottom = window.innerHeight + window.scrollY >= (document.documentElement.scrollHeight - 100);
            setMainWindowScrolledToBottom(bottom);
        };

        window.addEventListener('scroll', handleScroll);

        connection.on("CustomerConnected", chatCustomer => setChatCustomer(chatCustomer));
        connection.on("AgentsAvailable", agentsAvailable => setAgentIsAvailable(agentsAvailable));
        
        connection.start().then(() => {
            setConnection(connection);
            connection.invoke("LoginCustomer");

            connection.on("ChatUpdated", incomingChat => setChat(incomingChat))
        }).catch(function (err) {
            return console.error(err.toString());
        });
    }, []);

    const handleMessageFieldChange = (event) => {
        setMessageField(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (messageField.length === 0) {
            return;
        }

        const newMessage = {
            from: chatCustomer,
            text: messageField
        };
        
        connection.invoke("SendCustomerMessage", newMessage);

        setMessageField("");
    };

    const scrollToBottom = () => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat]);

    if (((chatCustomer === undefined || !agentIsAvailable) && chat.messages.length === 0) || (mainWindowScrolledToBottom && !isChatOpen)) {
        return (<></>);
    }
    
    return (
        <Wrapper
            isChatOpen={isChatOpen}>
            <TopBanner>
                        <Icon />
                        <h5>Chat with a Pro</h5>
                        <CloseIcon
                            isChatOpen={isChatOpen}
                            onClick={() => {
                                setIsChatOpen((prev) => !prev)
                            }} />
            </TopBanner>

            <MessageBox ref={messageBoxRef}>
                {chat.messages.map((message, index) => {
                    if (message.from.connectionId === "system") {
                        return (
                            <SystemMessage key={"Message-" + index}>{message.text}</SystemMessage>
                        )
                    }
                    return (
                        <Message fromAgent={message.from.connectionId !== chatCustomer.connectionId} key={"Message-" + index}><MessageHeader>{message.from.connectionId !== chatCustomer.connectionId ? message.from.name : ""}</MessageHeader> <MessageBody fromAgent={message.from.connectionId !== chatCustomer.connectionId}>{message.text}</MessageBody></Message>
                    );
                })}
            </MessageBox>

            <BottomBar>
                <ResizingTextArea
                    value={messageField}
                    onChange={handleMessageFieldChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Ask your question here..."
                    rows={1}
                    maxRows={4}
                />
                <SubmitButton onClick={handleSendMessage} disabled={messageField.length === 0 || !connection}>
                    Send
                </SubmitButton>
            </BottomBar>
        </Wrapper>
    );
}

export default ChatWindow;