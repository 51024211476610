import React from 'react';
import styled from 'styled-components';

const CenterContainerServices = styled.div`
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    border-radius: 25px;
`;

const WhatWeDoImage = styled.img`
    border-radius: 25px;
    width: 100%;
    transition: 0.3s ease;
    aspect-ratio: 1/1;

    ${CenterContainerServices}:hover & {
        transform: scale(1.1);
        filter: brightness(0.9) blur(5px);
    }
`;

const Title = styled.h2`
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
`;

const WhatWeDoSquare = ({ image, text }) => {
    return (
        <CenterContainerServices>
            <WhatWeDoImage src={image} alt={text} />
            <Title>{text}</Title>
        </CenterContainerServices>
    );
};

export default WhatWeDoSquare;