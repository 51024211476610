import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const SideNav = styled.div`
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: ${({ isNavClosed }) => (isNavClosed ? '3rem' : '100%')};
    font-size: 24px;
    padding-right: 1%;
    transition: 0.3s;
    white-space: nowrap;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`;

const ServiceNav = styled.div`
    cursor: pointer;
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding-left: 1rem;

    &:hover > div {
        background-color: var(--NiceBlue);
    }

    &:hover > a {
        color: var(--NiceBlue);
    }
`;

const Disk = styled.div`
    min-width: 1rem;
    min-height: 1rem;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s;
`;

const SLinks = styled(Link)`
    color: white;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: 0.3s;
    width: ${({ isNavClosed }) => (isNavClosed ? '0' : '100%')};
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InfoBlock = styled.section`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    overflow: hidden;
    background-image: url(${({ backgroundImageURL }) => backgroundImageURL});

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -1;
        transform: scale(1.05);
        filter: blur(5px);
    }
`;

const Back = styled.div`
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 1%;
    width: 60%;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 0.5rem;
`;

const Title = styled.b`
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
`;

const Text = styled.p`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
`;

const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
`;

const Chart = styled.img`
    padding: 1rem;
    background-color: var(--ZeusBrown);
    border-radius: 20px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
    max-width: 100%;
    height: auto;
`;

const demoData = [
    {
        "id": "Water",
        "title": "Water",
        "description": "At Energy Laboratories, water is more than just H2O. From drinking and groundwater analysis to pretreatment permits, landfill and oilfield monitoring, we use sensitive, sophisticated equipment to provide high-quality, dependable results. We are certified by the U.S. EPA and National Environmental Laboratory Accreditation Conference (NELAC) as well as many state and local regulatory agencies. <a href='https://www.example.com/certifications'>Click here for a complete list of all certifications.</a>",
        "listTitle": "Our technicians test water samples for:",
        "list": [
            "physical properties",
            "minerals",
            "nutrients",
            "trace metals",
            "cyanides",
            "organics",
            "pesticides and herbicides",
            "radionuclides",
            "bacteria"
        ],
        "backgroundImageURL": "./services/water_Blurred.jpg",
    },
    {
        "id": "Organic-Chemistry",
        "title": "Organic Chemistry",
        "description": "Organic chemistry crosses most scientific disciplines and affects numerous industries.",
        "listTitle": "That’s why the experienced scientists at Energy Laboratories offer a full spectrum of analyses, including:",
        "list": [
            "Drinking water under the Safe Drinking Water Act Phase II/V",
            "Landfill groundwater",
            "Priority Pollutants",
            "Resource Conservation and Recovery Act (RCRA)",
            "Hydrocarbons and hydrocarbon contaminated materials",
            "Petroleum/UST/LUST/Analysis",
            "Air Sampling",
            "Per- and Polyfluorinated Alkyl Substances (PFAS)"
        ],
        "backgroundImageURL": "./services/organicchem_Blurred.jpg",
    },
    {
        "id": "Waste",
        "title": "Waste",
        "description": "At Energy Laboratories, we want your waste—literally—so that you can get on with your business. Whether you need to analyze the soil at a reclamation site or the solids from effluent, we utilize the latest methods and possess the proper certifications including the Safe Water Drinking Act, Clean Water Act and Leaking Underground Storage Tank Program.",
        "listTitle": "As determined by the Resource Conservation Recovery Act (RCRA), waste is measured by four distinct characteristics:",
        "list": [
            "Ignitability",
            "Corrosivity",
            "Reactivity",
            "Toxicity"
        ],
        "backgroundImageURL": "./services/waste_Blurred.jpg",
    },
    {
        "id": "Radio-Chemistry",
        "title": "Radio Chemistry",
        "description": "When it comes to radiochemical analysis, experience matters. Energy Laboratories has been a leader in the field since 1984. We hold a radioactive materials license from the USNRC for radiological analyses on samples ranging from biological fluids, to yellowcake, to environmental background samples using methods from the Multi-Agency Radiological Analytical Protocols (MARLAP) manual. We maintain rigorous quality control to produce technically sound data.",
        "listTitle": "Energy Laboratories’ radiochemists use specialized and highly-sensitive equipment to analyze:",
        "list": [
            "Gross Alpha",
            "Gross Beta",
            "Gamma Emitters",
            "Radium226/228",
            "Radon222",
            "Uranium",
            "Isotopic Uranium",
            "Lead210",
            "Polonium210",
            "Strontium89/90"
        ],
        "backgroundImageURL": "./services/radiochem_Blurred.jpg",
    },
    {
        "id": "Aquatic-Toxicity",
        "title": "Aquatic Toxicity",
        "description": "For clients who need toxicity analysis, Energy Laboratories maintains bioassay facilities in Billings and Helena, Montana, certified by the National Environmental Laboratory Accreditation Program (NELAP) and Wyoming Department of Environmental Quality.",
        "listTitle": "Our scientists are experienced in analyzing:",
        "list": [
            "Whole Effluent Toxicity (WET)",
            "Analysis of fish, water fleas (Ceriodaphnia and Daphnia magna), green algae and duckweed",
            "Chronic and acute analysis"
        ],
        "backgroundImageURL": "./services/AquaticTox_Blurred.jpg",
    },
    {
        "id": "Soil",
        "title": "Soil",
        "description": "Energy Laboratories is committed to the long-term monitoring of land reclamation. Our scientists use chemical, physical and biological analysis to accurately assess soil samples and perform the full spectrum of required testing. We’re also experienced in the analysis of biosolids and plant tissues.",
        "listTitle": "Our laboratories are equipped to perform the following soil analyses:",
        "list": [
            "RCRA",
            "Title 22",
            "Petroleum",
            "Priority Pollutants",
            "Physical Characteristics",
            "Nutrients",
            "Minerals"
        ],
        "backgroundImageURL": "./services/soil_Blurred.jpg",
    },
    {
        "id": "Residential",
        "title": "Residential",
        "description": "Homeowners also benefit from Energy Laboratories’ knowledge and expertise. Our full-service residential analysis is EPA and state certified and fully compliant. <a href='https://www.example.com/certifications'>Click here for a complete list of all certifications.</a>",
        "listTitle": "Our certified testing services include:",
        "list": [
            "Total Coliform Bacteria",
            "E. coli",
            "Nitrates and Nitrites",
            "Hardness",
            "Fluoride",
            "Lead",
            "Arsenic",
            "Sulfate",
            "Iron",
            "Copper"
        ],
        "backgroundImageURL": "./services/residential_Blurred.jpg",
    },
    {
        "id": "Other",
        "title": "Other",
        "description": "Energy Laboratories has an extensive range of offerings and capabilities, many of which are listed here on our website. Energy Laboratories provides comprehensive crude oil analysis for oil and gas companies to determine sample characteristics, evaluate quality and monitor contaminants. ASTM tests include bottom sediment and water, API and specific gravity, hempel distillation, pour point, sulfur and viscosity. Oilfield water analysis is an important component in the exploration, placement, expansion and operation of oil and gas fields. Energy Laboratories oilfield water analysis includes EPA and ASTM testing to provide insight into the geologic formation, determine corrosion potential and other key information. In conformity with Method GPA 2261-95, Energy Laboratories provides thorough testing services to identify the composition of natural and liquefied petroleum gases. Calculated properties include gross and net BTU, specific gravity and pseudo-critical temperatures and pressure. The livelihood of farmers and ranchers depends on the health of their animals. Energy Laboratories offers a complement of services designed to evaluate the nutritional value of animal feed and test for any potential health hazards. To identify unknown materials in rock samples, geologists turn to Energy Laboratories. Our testing methods evaluate the scales, deposits, minerals and chemicals contained in each sample. This includes semi-quantitative analysis for inorganic compounds only. We also provide customized solutions for clients with unique needs.",
        "listTitle": "Additional capabilities include:",
        "list": [
            "Complete Crude Oil Analysis",
            "Routine Oilfield Water Analysis",
            "Natural Gas (NGA) and Liquefied Petroleum Gas (LPG) Analyses",
            "Feed",
            "Scale"
        ],
        "backgroundImageURL": "./services/other_Blurred.jpg",
    }
];

export function Services() {
    const [isNavClosed, setIsNavClosed] = useState(true);
    const [servicesData, setServicesData] = useState([]);

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        initialPageScroll();
    }, [servicesData]);

    const fetchServices = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_APILink + "/api/ELIServices", {
                method: 'GET'
            });
            const data = await response.json();

            setServicesData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const initialPageScroll = () => {
        const hash = decodeURIComponent(window.location.hash.substring(1));
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    };

    return (
        <div>
            <SideNav
                isNavClosed={isNavClosed}
                onMouseEnter={() => setIsNavClosed(false)}
                onMouseLeave={() => setIsNavClosed(true)}
            >
                {servicesData.map(service => (
                    <ServiceNav key={service.id}>
                        <Disk />
                        <SLinks
                            to={service.title}
                            smooth
                            duration={100}
                            offset={-100}
                            isNavClosed={isNavClosed}
                        >
                            {service.title}
                        </SLinks>
                    </ServiceNav>
                ))}
            </SideNav>
            <ServicesContainer>
                {servicesData.map(service => (
                    <InfoBlock key={service.id} id={service.title} backgroundImageURL={`data:${service.mimeType};base64,${service.image}`}>
                        <Back>
                            <div>
                                <Title>{service.title}</Title>
                                <Text dangerouslySetInnerHTML={{ __html: service.description }} />
                            </div>
                            <div>
                                <Text>{service.listTitle}</Text>
                                {service.list && (
                                    <ul>
                                        {service.list.map((item, index) => {
                                            return (
                                            <li key={index}><Text>{item.value}</Text></li>
                                        )})}
                                    </ul>
                                )}
                            </div>
                        </Back>
                    </InfoBlock>
                ))}
            </ServicesContainer>
        </div>
    );
}