import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AccordionBox } from './Accordion';
import { Link } from 'react-router-dom';

const HelpfulLinksContainer = styled.div`
  padding: 3em;
  min-height: 75vh;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const Boxes = styled.div`
  margin-right: 2em;
  width: 70%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Science = styled.div`
  width: 30%;

  @media (max-width: 1000px) {
    width: 100%;
    padding-bottom: 1em;
  }

  img {
    width: 99.99%;
    height: auto;
  }
`;

const Heading = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--ZeusBrown);
`;

const Paragraph = styled.p`
  color: var(--ZeusBrown);
  padding-bottom: 1em;
`;

const StyledLink = styled(Link)`
  color: var(--NiceBlue);
  text-decoration: none;

  &:visited {
    color: var(--NiceBlue);
  }

  &:hover {
    color: var(--bs-link-color);
  }

  &:active {
    color: var(--NiceBlue);
  }
`;

const HelpfulLinks = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, []);

  const fetchData = async () => {
    fetch(process.env.REACT_APP_APILink + "/api/LinkCategories", {
      method: 'GET'
    })
      .then(response => response.json())
      .then(responseData => {
        setData(responseData);
        setLoading(false);
      });
  };

  const makePage = () => {
    if (!data.length) return null;

    return data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => (
        <AccordionBox
          key={category.id}
          title={category.name}
          content={
            <ul className="offset aContent">
              {category.links
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((link) => {
                  var url;

                  switch (link.discriminator) {
                    case "NavigationLink":
                      url = link.url
                      break;

                    case "ContentLink":
                      url = process.env.REACT_APP_APILink + "/api/Links/Content/" + link.fileName;
                      break;

                    default:
                      url = null;
                  }

                  return (<li key={link.id}>
                    <StyledLink to={url} target="_blank" rel="noreferrer">
                      {link.title}
                    </StyledLink>
                  </li>)
                }
                )}
            </ul>
          }
        />
      ));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <HelpfulLinksContainer>
      <VerticalFlex>
        <Heading>
          <b>Helpful Links</b>
        </Heading>
        <Paragraph>
          Click on one of the categories below to view links related to that category
        </Paragraph>
        <HorizontalFlex>
          <Boxes>{makePage()}</Boxes>
          <Science>
            <img src="./HelpfulLinks.jpg" alt="" />
          </Science>
        </HorizontalFlex>
      </VerticalFlex>
    </HelpfulLinksContainer>
  );
};

export default HelpfulLinks;