// The following code was written by an AI assistant.
import React, { useEffect, useState } from 'react';
import { AccordionBox } from './Accordion';
import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    gap: 1em;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

const Category = styled.div`
    width: calc(50% - 0.5em);
    margin: 0 auto;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

const Dark = styled.p`
    color: var(--ZeusBrown);
`;

const DarkTitle = styled.h2`
    color: var(--ZeusBrown);
    font-weight: bold;
`;

const FAQ = () => {
    const [faqData, setFaqData] = useState();

    const fetchFaqData = async () => {
        fetch(process.env.REACT_APP_APILink + "/api/FAQCategories", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setFaqData(data);
            });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        fetchFaqData();
    }, []);

    return (
        <div className="page-padding">
            <DarkTitle>FAQ</DarkTitle>
            <Dark>Every question deserves a good answer, which is why we’ve prepared these responses to the questions Energy Laboratories receives most often. If the answer to your question is not included below, or if you need additional information, please contact us.</Dark>
            <Dark>Please contact a Project manager for any other questions.</Dark>
            <Flex>
                {faqData && faqData.map((category, index) => (
                    <Category key={index}>
                        <h1>{category.name}</h1>
                        {category.faQs.map((faq, faqIndex) => {
                            const content = (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} ></div>
                                    {faq.embed && <div dangerouslySetInnerHTML={{ __html: faq.embed }}></div>} 
                                </>
                            )

                            return (
                                <AccordionBox key={faqIndex} title={faq.question} content={content} />
                            )
                        })}
                    </Category>
                ))}
            </Flex>
        </div>
    );
};

export default FAQ;
