import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ChatProvider } from './components/Contexts/ChatContext';

const App = () => {
    const doCaptcha = process.env.REACT_APP_CAPTCHA_SITE_KEY !== undefined;

    if (doCaptcha) {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                container={{
                    element: 'captcha_badge',
                    parameters: {
                        badge: 'bottomright',
                        theme: 'dark',
                    }
                }}
            >
                <ChatProvider>
                    <Layout>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </Layout>
                </ChatProvider>
            </GoogleReCaptchaProvider>
        );
    } else {
        console.warn("Environment variable REACT_APP_CAPTCHA_SITE_KEY is not set. Recaptcha will not be used. Please set this variable in your server environment.");
        return (
            <ChatProvider>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </ChatProvider>
        );
    }
}

export default App;