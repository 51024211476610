import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import leftArrow from '../Images/Left.png';
import rightArrow from '../Images/Right.png';
import image1 from '../Images/Mining.png';
import image2 from '../Images/Oilfield.png';
import image3 from '../Images/Refinery.png';

const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;

const ImageGallery = styled.div`
  width: 100%;
`;

const ImageOffice = styled.img`
  width: 100%;
  height: auto;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const GalleryButton = styled.button`
  position: absolute;
  height: 15%;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: 0px solid #ccc;
  color: #333;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7;
  transition: opacity 0.3s ease, background 0.3s ease;

  &:hover {
    opacity: 1;
    background: rgba(211, 211, 211, 0.5);
  }
`;

const PrevButton = styled(GalleryButton)`
  left: 1em;
`;

const NextButton = styled(GalleryButton)`
  right: 1em;
`;

const ArrowIcon = styled.img`
  width: 40px;
  height: 40px;

  @media (max-width: 1000px) {
    width: 30px;
    height: 30px;
  }
`;

const Gallery = () => {
  const images = [image1, image2, image3];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(nextImage, 4000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <GalleryContainer>
      <PrevButton onClick={prevImage}>
        <ArrowIcon src={leftArrow} alt="Previous" />
      </PrevButton>
      <ImageGallery>
        <ImageOffice src={images[currentIndex]} alt={`Image ${currentIndex}`} />
      </ImageGallery>
      <NextButton onClick={nextImage}>
        <ArrowIcon src={rightArrow} alt="Next" />
      </NextButton>
    </GalleryContainer>
  );
};

export default Gallery;
