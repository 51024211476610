import React from 'react';
import styled from 'styled-components';

const CenterContainer = styled.div`
    flex: 1;
    margin: 1em;
    height: 300px;
    ${props => props.hide && `@media (max-width: 1000px) {
        display: none;
    }`}
`;

const Centered = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--ZeusBrown);
    border-radius: 30px;
    height: 100%;
    padding: 2em;
`;

const CustomerReview = ({ className, text, reviewer, hide = false }) => {

    return (
        <CenterContainer className={className} hide={hide}>
            <Centered>
                <p className="roboto-regular customer-text light">{text}</p>
                <br />
                <p className="roboto-regular customer-text light"><i>{reviewer}</i></p>
            </Centered>
        </CenterContainer>
    );
};

export default CustomerReview;