export const newQuoteEmailTemplate = (props) => {
    // - dataValidation
    return `<div style="margin: 0em 5em 0em 5em; ">
    <h1 style="text-align: center;">Bottle Ordering Information (New Quote)</h1>
    <table style="width:100%;">
        <tr>
            <th style="width: 70%; text-align: left;">
                <h2 style="display:inline;">Client Information</h2>
            </th>
            <th style="width: 20%; text-align: right; margin-right: 5em">
                <p style="display: inline;">Date: ${props.date}</p>
            </th>
        </tr>
    </table>
    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Name of Submitter / Contact: </strong></th>
            <th style="width:auto; text-align:right;">${props.contact}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Company: </strong></th>
            <th style="width:auto; text-align:right;">${props.company}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Contact phone number: </strong></th>
            <th style="width:auto; text-align:right;">${props.phoneNumber}</th>
        </tr>
    </table>

    <h2>Testing / Analysis Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Name of Project: </strong></th>
            <th style="width:auto; text-align:right;">${props.projectName}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Schedule Name(s): </strong></th>
            <th style="width:auto; text-align:right;">${props.scheduleNames}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Drinking Water analysis: </strong></th>
            <th style="width:auto; text-align:right;">${props.drinkingWater}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>PWS ID: </strong></th>
            <th style="width:auto; text-align:right;">${props.PWS}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Reporting Limits: </strong></th>
            <th style="width:auto; text-align:right;">${props.reportingLimits}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Specific testing method: </strong></th>
            <th style="width:auto; text-align:right;">${props.method}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Filtered in the field: </strong></th>
            <th style="width:auto; text-align:right;">${props.filtered}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Data Validation/QC Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.dataValidation}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Parameter needs: </strong></th>
            <th style="width:auto; text-align:right;">${props.parameterNeeds}</th>
        </tr>
    </table>

    <h2>Quote Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Is there a quote number: </strong></th>
            <th style="width:auto; text-align:right;">${props.hasQuoteNumber}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Quote Number: </strong></th>
            <th style="width:auto; text-align:right;">${props.quoteNumber}</th>
        </tr>
    </table>

    <h2>Shipping / Receiving Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive order by: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Shipping Address: </strong></th>
            <th style="width:auto; text-align:right;">${`${props.addressName}, ${props.addressStreet1}, ${props.addressStreet2} ${props.addressCity}, ${props.addressState} ${props.addressZip}`}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Residential address: </strong></th>
            <th style="width:auto; text-align:right;">${props.isResidential}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Need Return Label: </strong></th>
            <th style="width:auto; text-align:right;">${props.needReturnLabel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send back to lab date: </strong></th>
            <th style="width:auto; text-align:right;">${props.returnDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Will samples fly: </strong></th>
            <th style="width:auto; text-align:right;">${props.isFlying}</th>
        </tr>
    </table>
</div>
`
};

export const newOrderEmailTemplate = (props) => {
    return `<div style="margin: 0em 5em 0em 5em; ">
    <h1 style="text-align: center;">Bottle Ordering Information (New Order)</h1>
    <table style="width:100%;">
        <tr>
            <th style="width: 70%; text-align: left;">
                <h2 style="display:inline;">Client Information</h2>
            </th>
            <th style="width: 20%; text-align: right; margin-right: 5em">
                <p style="display: inline;">Date: ${props.date}</p>
            </th>
        </tr>
    </table>
    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Name of Submitter / Contact: </strong></th>
            <th style="width:auto; text-align:right;">${props.contact}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Company: </strong></th>
            <th style="width:auto; text-align:right;">${props.company}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Contact phone number: </strong></th>
            <th style="width:auto; text-align:right;">${props.phoneNumber}</th>
        </tr>
    </table>

    <h2>Testing / Analysis Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Name of Project: </strong></th>
            <th style="width:auto; text-align:right;">${props.projectName}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Drinking Water analysis: </strong></th>
            <th style="width:auto; text-align:right;">${props.drinkingWater}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>PWS ID: </strong></th>
            <th style="width:auto; text-align:right;">${props.PWS}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Reporting Limits: </strong></th>
            <th style="width:auto; text-align:right;">${props.reportingLimits}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Specific testing method: </strong></th>
            <th style="width:auto; text-align:right;">${props.method}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Filtered in the field: </strong></th>
            <th style="width:auto; text-align:right;">${props.filtered}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Parameter needs: </strong></th>
            <th style="width:auto; text-align:right;">${props.parameterNeeds}</th>
        </tr>
    </table>

    <h2>Additional Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Additional Information: </strong></th>
            <th style="width:auto; text-align:right;">${props.additionalInformation}</th>
        </tr>
    </table>

    <h2>Quote Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Is there a quote number: </strong></th>
            <th style="width:auto; text-align:right;">${props.hasQuoteNumber}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Quote Number: </strong></th>
            <th style="width:auto; text-align:right;">${props.quoteNumber}</th>
        </tr>
    </table>

    <h2>Shipping / Receiving Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive order by: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Shipping Address: </strong></th>
            <th style="width:auto; text-align:right;">${`${props.addressName}, ${props.addressStreet1}, ${props.addressStreet2} ${props.addressCity}, ${props.addressState} ${props.addressZip}`}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Residential address: </strong></th>
            <th style="width:auto; text-align:right;">${props.isResidential}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Need Return Label: </strong></th>
            <th style="width:auto; text-align:right;">${props.needReturnLabel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send back to lab date: </strong></th>
            <th style="width:auto; text-align:right;">${props.returnDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Will samples fly: </strong></th>
            <th style="width:auto; text-align:right;">${props.isFlying}</th>
        </tr>
    </table>
</div>
`
};

export const repeatOrderEmailTemplate = (props) => {
    return `<div style="margin: 0em 5em 0em 5em; ">
    <h1 style="text-align: center;">Bottle Ordering Information (Repeat Order)</h1>
    <table style="width:100%;">
        <tr>
            <th style="width: 70%; text-align: left;">
                <h2 style="display:inline;">Client Information</h2>
            </th>
            <th style="width: 20%; text-align: right; margin-right: 5em">
                <p style="display: inline;">Date: ${props.date}</p>
            </th>
        </tr>
    </table>
    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Name of Submitter / Contact: </strong></th>
            <th style="width:auto; text-align:right;">${props.contact}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Company: </strong></th>
            <th style="width:auto; text-align:right;">${props.company}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Contact phone number: </strong></th>
            <th style="width:auto; text-align:right;">${props.phoneNumber}</th>
        </tr>
    </table>

    <h2>Order Identifier</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Order Identifier: </strong></th>
            <th style="width:auto; text-align:right;">${props.previousIdentifier}</th>
        </tr>
    </table>

    <h2>Shipping / Receiving Information</h2>

    <table style="width:50%;">
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive order by: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Receive Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.receiveServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Shipping Address: </strong></th>
            <th style="width:auto; text-align:right;">${`${props.addressName}, ${props.addressStreet1}, ${props.addressStreet2} ${props.addressCity}, ${props.addressState} ${props.addressZip}`}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Residential address: </strong></th>
            <th style="width:auto; text-align:right;">${props.isResidential}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Carrier: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendCarrier}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send Service Level: </strong></th>
            <th style="width:auto; text-align:right;">${props.sendServiceLevel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Need Return Label: </strong></th>
            <th style="width:auto; text-align:right;">${props.needReturnLabel}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Send back to lab date: </strong></th>
            <th style="width:auto; text-align:right;">${props.returnDate}</th>
        </tr>
        <hr />
        <tr>
            <th style="width:auto; text-align:left"><strong>Will samples fly: </strong></th>
            <th style="width:auto; text-align:right;">${props.isFlying}</th>
        </tr>
    </table>
</div>
`
};