import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CustomerReview from './home/CustomerReview';
import Gallery from './home/Gallery';
import ServiceDisplay from './home/ServiceDisplay';
import { ChatContext } from './Contexts/ChatContext';

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    @media (max-width: 765px) {
        flex-direction: column;
    }
`;

const FlexStack = styled.div`
    display:flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
`;

const BottomFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    @media (max-width: 765px) {
        flex-direction: column;
    }
`;

const GalleryStyled = styled.div`
    width: 45%;
    height: auto;
    
    @media (max-width: 765px) {
        width: 100%;
    }
`;

const GeneralInfo = styled.div`
    padding: 5%;
    margin: auto;
    text-align: center;
    font-size: larger;
    color: var(--ZeusBrown);
`;

const Title = styled.h1`
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: var(--ZeusBrown);
    display: block;
    padding: 1rem;
    font-weight: bold;
    margin: 0;
`;

const FlexReview = styled.div`
    display: flex;
    height: auto;

    @media (max-width: 1000px) {
        flex-direction: column;

    }

    @media (max-width: 765px) {
    & > *:not(:first-child) {
            display: none;
        }
    }
`;

const OrderText = styled.h5`
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
`;

const OrderButton = styled.button`
    width: 15em;
    height: 3em;
    margin: 10px;
    padding: 0;
    transition: background-color 0.3s ease;
    color: white;
    border: none;
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 400; 

    &:hover {
        background-color: var(--NiceBlue);
    }
`;

const OrderButtonPrimary = styled(OrderButton)`
    background-color: var(--RoseRed);
`;

const OrderButtonSecondary = styled(OrderButton)`
    background-color: var(--CadmiumOrange);
`;

const OrderButtonTertiary = styled(OrderButton)`
    background-color: var(--ZeusBrown);
`;

const FlexContainerButton = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1.5%;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media (max-width: 765px) {
        flex-direction: column;
    }
`;

const ImageScience = styled.div`
    flex: 1;
`;

const OrderItem = styled.div`
    flex: 1;
    margin: auto;
    @media (max-width: 1000px) {
        margin-top: 1em;
    }
`;

const ScienceImage = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('Images/order.jpeg');
    background-size: cover;
`;

const FAQRibbon = styled.div`
    background-color: var(--NiceBlue);
    color: white;
    padding: 1em;
    text-align: center;
    font-family: Montserrat, sans-serif;
`;

const FAQButton = styled(Link)`
    display: inline-block;
    width: 300px;
    margin: 15px;
    padding: 10px;
    transition: 0.3s ease;
    background-color: white;
    color: var(--NiceBlue);
    border: none;
    border-radius: 20px;
    font-weight: 400; 
    text-decoration: none;
    font-size: 1.5rem;

    &:hover {
        background-color: var(--ZeusBrown);
        color: var(--NiceBlue);
    }

`;

const NewsletterRibbon = styled.div`
    background-color: var(--RoseRed);
    color: white;
    padding: 0.5rem;
    text-align: center;
`;

const NewsletterTextInput = styled.input`
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
`;

const NewsletterButton = styled.button`
    display: inline-block;
    margin: 10px;
    padding: 10px 1rem;
    transition: 0.3s ease;
    background-color: white;
    color: var(--ZeusBrown);
    border: none;
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 400; 
    text-decoration: none;
    font-size: 1rem;

    &:hover {
        background-color: var(--ZeusBrown);
        color: white;
    }

`;

const ChatLink = styled.span`
    color: ${(props) => (props.active ? "var(--RoseRed)" : "var(--ZeusBrown)")};
    cursor: ${(props) => (props.active ? "pointer" : "default")};
    text-decoration: ${(props) => (props.active ? "underline" : "none")};
`;

const ButtonLink = styled(Link)`
    width: min-content;
`;

const Home = () => {
    const [testimonials, setTestimonials] = useState(null);
    const { chatOpen, setChatOpen, chatAvailable, setChatAvailable } = useContext(ChatContext);
    const [emailText, setEmailText] = useState("");
    const [webConfig, setWebConfig] = useState(null);

    useEffect(() => {
        fetchData();
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    const fetchData = async () => {
        fetch(process.env.REACT_APP_APILink + "/api/Testimonials", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                let temp_testimonials = shuffleArray(data);
                let output_testimonials = [];
                if (temp_testimonials.length >= 3) {
                    for (let i = 0; i < 3; i++) {
                        temp_testimonials[i].message = "\"" + temp_testimonials[i].message + "\"";
                        temp_testimonials[i].name = "- " + temp_testimonials[i].name;
                        output_testimonials.push(temp_testimonials[i]);
                    }
                }
                setTestimonials(output_testimonials);
            }).catch((error) => {
                console.error('Error:', error);
            });
        
        fetch(process.env.REACT_APP_APILink + "/api/WebsiteConfig", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setWebConfig(data);
            }).catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleSubscribeNewsletter = async () => {
        if (emailText === "") {
            alert("Please enter an email address to subscribe to the newsletter.");
            return;
        }
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (!emailRegex.test(emailText)) {
            alert("Please enter a valid email address to subscribe to the newsletter.");
            return;
        }
        const email = {
            from: webConfig["subscriptionNotificationEmail"],
            to: webConfig["subscriptionNotificationEmail"],
            subject: "New Newsletter Subscription - " + emailText,
            body: "A new user has subscribed to the newsletter. Email: " + emailText
        };
        SendEmail(email);
    };

    const SendEmail = (message) => {
        fetch(process.env.REACT_APP_APILink + '/api/Email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }


    return (
        <>
            <Title>Welcome</Title>
            <FlexContainer>
                <GalleryStyled>
                    <Gallery />
                </GalleryStyled>

                <FlexStack>
                    <GeneralInfo>
                        <p className="roboto-regular">
                            Energy Laboratories, Inc., the leader in chemical and environmental analysis for companies throughout North America. Our depth of knowledge and years of experience means we can meet the full spectrum of analytical testing needs for water, waste, soil, air and petroleum products.
                        </p>
                    </GeneralInfo>


                    <OrderItem>
                        <Title><b>Need to make an order?</b></Title>

                        <OrderText>
                            If you are an existing client and would like to create a simple order and know exactly what you may need, you may repeat a previous order or create a New Order.
                        </OrderText>

                        <OrderText>
                            If you are a new client, or need help selecting appropriate tests, request a quote below. Have questions about reporting limits, certifications, sampling, etc. <Link to="/contact-us">contact</Link> or <ChatLink active={chatAvailable} onClick={() => setChatOpen(chatAvailable)}>chat</ChatLink> with us during regular business hours.
                        </OrderText>

                        <FlexContainerButton>
                            <ButtonLink to="/repeat-order"><OrderButtonPrimary>Repeat Order</OrderButtonPrimary></ButtonLink>
                            <ButtonLink to="/new-order"><OrderButtonSecondary>New Order</OrderButtonSecondary></ButtonLink>
                            <ButtonLink to="/request-quote"><OrderButtonTertiary>Request Quote</OrderButtonTertiary></ButtonLink>
                        </FlexContainerButton>
                    </OrderItem>
                </FlexStack>
            </FlexContainer>

            <Title>What We Do</Title>

            <ServiceDisplay />

            {testimonials && (
                <FlexReview>
                    {testimonials.map((testimonial, index) => {
                        return (
                            <CustomerReview key={index} text={testimonial.message} reviewer={testimonial.name} />
                        )
                    })}
                </FlexReview>
            )}

            <BottomFlexContainer>
                <ImageScience>
                    <ScienceImage alt="A man observing scientific equipment." />
                </ImageScience>
            </BottomFlexContainer>

            <FAQRibbon>
                <h3>Still have questions?</h3>
                <FAQButton to="/faq">Check out our FAQ</FAQButton>
            </FAQRibbon>

            <NewsletterRibbon>
                <h3>Subscribe to our newsletter to stay up to date on the latest in analytical chemistry</h3>
                <NewsletterTextInput type="text" placeholder="SpeterParker1962@website.com" onChange={(event) => setEmailText(event.target.value)} value={emailText} />
                <NewsletterButton onClick={handleSubscribeNewsletter}>Subscribe</NewsletterButton>
            </NewsletterRibbon>
        </>
    );
};

export default Home;
