import React, { useEffect } from 'react';
import styled from "styled-components";

const StyledCookieBanner = styled.div`
    position: fixed;
    bottom: ${props => props.showBanner ? '1rem' : '-100%'};
    left: 1rem;
    width: 50%;
    background-color: var(--ZeusBrown);
    color: white;
    padding: 10px;
    text-align: justify;
    z-index: 10;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    transition: 0.5s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Button = styled.button`
    background-color: white;
    border: none;
    border-radius: 5px;

`;

const CookieBanner = ({ cookiesAccepted, setCookiesAccepted }) => {
    const [showBanner, setShowBanner] = React.useState(false);

    const handleAcceptCookies = () => {
        setCookiesAccepted(true);
        setShowBanner(false);
        localStorage.setItem('cookiesAccepted', 'true');
    };

    const handleDeclineCookies = () => {
        setCookiesAccepted(false);
        setShowBanner(false);
    };

    useEffect(() => {
        // Check if the user has already accepted cookies
        const cookies = localStorage.getItem('cookiesAccepted');
        if (cookies === 'true') {
            setCookiesAccepted(true);
            setShowBanner(false);
        } else {
            setShowBanner(true);
        }
    }, []);

    return (
        <StyledCookieBanner showBanner={showBanner}>
            <div>
                This website uses cookies to improve user experience.
                By accepting all cookies, you agree to our practices as outlined in
                our <a href='/privacy-policy'>privacy policy</a> and <a href="/terms-and-conditions">terms and conditions</a>.
            </div>
            <ButtonContainer>
                <Button onClick={handleAcceptCookies} >Accept cookies</Button>
                <Button onClick={handleDeclineCookies} >Decline cookies</Button>
            </ButtonContainer>
        </StyledCookieBanner>
    );
};

export default CookieBanner;