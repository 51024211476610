// The following code was written by an AI assistant.
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const AccordionContainer = styled.div`
`;

const AccordionButton = styled.button`
    background-color: var(${({ active }) => active ? "--FiftyPercentOrange" : "--ThirtyPercentOrange"});
    color: var(--ZeusBrown);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    ${({ active }) => active ? "border-radius: 25px 25px 0px 0px;" : "border-radius: 25px;"}
    outline: none;   

    &:hover {
        background-color: var(--FiftyPercentOrange);
    }
`;

const Panel = styled.div`
    ${({ maxHeight }) => maxHeight != "0px" ? "padding: 18px;" : "padding: 0 18px;"};
    background-color: var(--FiftyPercentOrange);
    color: var(--ZeusBrown);
    overflow: hidden;
    margin-bottom: 1em;
    border-radius: 0px 0px 25px 25px;
    text-align: left;
    font-size: larger;
    height: ${({ maxHeight }) => maxHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const AccordionText = styled.h4`
    font-size: 28px;
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 1000px) {
        font-size: 20px;
    }
`;

const ArrowImage = styled.img`
    display: block;
    margin-left: auto;
    width: 3%;
    height: 3%;
    margin-right: 1em;

    @media (max-width: 1000px) {
        width: 5%;
        height: 5%;
    }
`;

export const AccordionBox = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const [isActive, setIsActive] = useState(false);
    const panelRef = useRef(null);
    const upArrow = "./Logos/up.png";
    const downArrow = "./Logos/down.png";

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (panelRef.current) {
            if (isOpen) {
                setMaxHeight(`auto`);
                setIsActive(true);
            } else {
                setMaxHeight('0px');
                setIsActive(false);
            }
        }
    }, [isOpen]);

    return (
        <AccordionContainer>
            <AccordionButton onClick={toggleAccordion} active={isActive}>
                <FlexContainer>
                    <AccordionText>{title}</AccordionText>
                    <ArrowImage src={isOpen ? upArrow : downArrow} alt="" />
                </FlexContainer>
            </AccordionButton>
            <Panel ref={panelRef} maxHeight={maxHeight}>
                <div>{content}</div>
            </Panel>
        </AccordionContainer>
    );
};
// The above code was written by an AI assistant.
