import React, { createContext, useState } from 'react';

// Create a new context
const ChatContext = createContext();

// Create a provider component
const ChatProvider = ({ children }) => {
    // State for chat messages
    const [chatOpen, setChatOpen] = useState(false);
    const [chatAvailable, setChatAvailable] = useState(false);

    // Value object to be provided by the context
    const value = {
        chatOpen,
        setChatOpen,
        chatAvailable,
        setChatAvailable
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

export { ChatContext, ChatProvider };