import React, { useEffect } from 'react';
import './AboutUs.css';

export const AboutUs = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div className="page-padding">
            <h1 className="montserrat-regular dark"><b>About Us</b></h1>
            <p className="dark">Founded in 1952, Energy Laboratories, Inc. (ELI) provides independent, quality-controlled, and confidential analytical services. With four laboratories, across Montana and Wyoming, ELI is equipped to meet the analytical needs of mining companies, oil & gas operations, petroleum refining, electrical utilities, industrial and manufacturing firms, large and small municipal water systems, professional engineering and consulting firms, and federal, state, and municipal government agencies. Providing services to clients across the country from Alaska to Florida. ELI maintains certification with DOD, EPA, and NELAC to support regulatory requirements, and now offers PFAS and PFOA analysis. We became employee-owned (ESOP) in 2007 to benefit the employees as a result of our success.</p>
            <br />
            <h2 className="montserrat-regular dark"><b>Board of Directors</b></h2>
            <div className="board-of-directors">
                <div>
                    <img src="Directors/JohnHager.png" className="centered-text" alt="" />
                    <p className="centered-text dark">President - Jon Hager</p>
                </div>
                <div>
                    <img src="Directors/CindyRohrer.png" className="centered-text" alt="" />
                    <p className="centered-text dark">Vice President- Cindy Rohrer</p>
                </div>
                <div>
                    <img src="Directors/TracyDangerfield.png" className="centered-text" alt="" />
                    <p className="centered-text dark">Treasurer/Secretary: Tracy Dangerfield</p>
                </div>
                <div>
                    <img src="Directors/LisaBradley.png" className="centered-text" alt="" />
                    <p className="centered-text dark">Member: Lisa Bradley</p>
                </div>
                <div>
                    <img src="Directors/WilliamBrown.png" className="centered-text" alt="" />
                    <p className="centered-text dark">Founding Member: William Brown</p>
                </div>
            </div>
            <hr />
            <div className="centered-text">
                <h1 className="montserrat-regular dark"><b>Energy Labs History</b></h1>
                <br />
                <img src="Timeline.png" alt="" className="timeline" />
            </div>
            <hr />
            <div className="capability-table">
                <div>
                    <h3 className="montserrat-regular dark">Core Competencies</h3>
                    <p className="dark">Energy Laboratories is a full-service analytical laboratory striving for superior quality and data integrity. Independent, quality-controlled, and confidential analytical services are available for chemical and environmental testing of water, waste, soil, air, and petroleum product samples. Our laboratories are staffed with highly skilled experienced chemists and technicians that analyze samples with state of the art equipment.</p>
                    <br />
                    <p className="dark">Choosing Energy Laboratories will provide you with the following benefits:</p>
                    <br />
                    <ul>
                        <li>Excellent QA/QC standards emerging from multiple laboratory audits by outside professional auditors, private companies, public and internal audits.</li>
                        <br />
                        <li>Highly experienced management averaging 15+ years with Energy Laboratories.</li>
                        <br />
                        <li>Access to experienced problem-solving staff with high customer service and resolution standards.</li>
                        <br />
                        <li>Quick TAT addressed daily with meetings and goals.</li>
                        <br />
                        <li>Electronic excellence with our Omega Laboratory Information Management System, EnergySource as your client portal, and EnergyExpress for high volume sampling.</li>
                        <br />
                        <li>Multiple locations for best coverage and backup for all jobs.</li>
                        <br />
                        <li>Constant drive to improve on our quality and service to our clients.</li>
                    </ul>
                    <br />
                </div>
                <div>
                    <h3 className="montserrat-regular dark">Differentiators</h3>
                    <ul>
                        <li>Quick TAT-bottle orders can ship same day as the request is submitted.</li>
                        <br />
                        <li>Free consultation service for information or sampling guidance.</li>
                        <br />
                        <li>Ease of use for the sampler-Bottle orders are designed for simplicity and color coded.</li>
                        <br />
                        <li>Management and 24/7 support is accessible directly for questions.</li>
                        <br />
                        <li>Accuracy of our data can be defended through reruns, and our proofing systems.</li>
                        <br />
                        <li>Utilized by other laboratories for subcontracted work.</li>
                        <br />
                        <li>Our staff is current on educational classes and all laboratory issues and trends.</li>
                        <br />
                        <li>Extensive laboratory experience since 1952.</li>
                    </ul>
                </div>
                <div>
                    <h3 className="montserrat-regular dark">Pertinent Codes</h3>
                    <ul>
                        <li>Federal Tax ID 81-0243326</li>
                        <br />
                        <li>DUNS 055920987</li>
                        <br />
                        <li>NAICS Code 541380, 541620, 562910</li>
                        <br />
                        <li>CAGE Code 7E021</li>
                    </ul>
                </div>
                <div>
                    <h3 className="montserrat-regular dark">Company Data</h3>
                    <ul>
                        <li>Number of employees: 150</li>
                        <br />
                        <li>Insurance and Bonding: 2M</li>
                        <br />
                        <li>Company Incorporated: 1952</li>
                        <br />
                        <li>Certifications/Compliance Programs: DOD, PFAS, NELAC, NPDES, RCRA, USEPA, SDWA</li>
                        <br />
                        <li>Multiple State Certifications</li>
                        <br />
                        <li>Small Business</li>
                    </ul>
                </div>
            </div>
            <div>
            Energy Laboratories Inc is an Equal Opportunity Employer (EOE). If you have interest in becoming part of our organization, please see our open positions on <a href='https://www.indeed.com/cmp/Energy-Laboratories,-Inc-1?from=mobviewjob&tk=1i560l3ldit7o800&fromjk=2f32350f94466cfc&attributionid=mobvjcmp'>Indeed by clicking here.</a>
            </div>
        </div>
    );
};