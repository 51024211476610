import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    const style = {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    }

    return (
        <div className="page-padding" style={style}>
            <h3 className="montserrat-regular dark"><b>Website Terms and Conditions</b></h3>
            <p className="dark">Please read these terms and conditions ("terms and conditions", "terms") carefully before using <a href="https://www.energylab.com/">https://www.energylab.com/</a> website ("website", "service") operated by Energy Laboratories Inc. ("us", 'we", "our").</p>

            <h5 className="montserrat-regular dark"><b>Conditions of use</b></h5>
            <p className="dark">By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. Energy Laboratories Inc. only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>

            <h5 className="montserrat-regular dark"><b>Privacy policy</b></h5>
            <p className="dark">Energy Laboratories Inc. respects each individual website user's right to privacy.  Any personal information that is collected will not be disclosed to any third party except as required by applicable law or in the presence of a business associate arrangement with Energy Laboratories Inc.  Before you continue using our website, we advise you to read our <Link to="/privacy-policy">privacy policy</Link> regarding our user data collection. We employ the use of cookies. By accessing our website, you agreed to use cookies in agreement with the stated privacy policy. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Cookies are also used to help us understand traffic visitations to our website. Some of our affiliates or linked organizations may also use cookies. It will help you better understand our practices.</p>

            <h5 className="montserrat-regular dark"><b>Age restriction</b></h5>
            <p className="dark">You must be at least 16 (sixteen) years of age before you can use this website. By using this website, you warrant that you are at least 16 years of age and you may legally adhere to this Agreement. Energy Laboratories Inc. assumes no responsibility for liabilities related to age misrepresentation.</p>

            <h5 className="montserrat-regular dark"><b>Intellectual property</b></h5>
            <p className="dark">You agree that all materials, products, and services provided on this website are the property of Energy Laboratories Inc. its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute Energy Laboratories Inc's intellectual property in any way, including electronic, digital, or new trademark registrations.</p>
            <p className="dark">You grant Energy Laboratories Inc. a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</p>

            <h5 className="montserrat-regular dark"><b>User accounts</b></h5>
            <p className="dark">As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password as linked to EnergySource.</p>
            <p className="dark">Information obtained as a result of registration will not be sold or given to other private companies for marketing purposes unless it is specifically stated when the information is provided and the user "opts in" to having their information used in the stated fashion.  The information collected is subject to the access and confidentiality provisions of Montana Code and Federal law.</p>
            <p className="dark">If you think there are any possible issues regarding the security of your account on the EnergySource website affiliated with this site, inform us immediately so we may address them accordingly.</p>
            <p className="dark">We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion.</p>

            <h5 className="montserrat-regular dark"><b>iFrames</b></h5>
            <p className="dark">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

            <h5 className="montserrat-regular dark"><b>Linked Content</b></h5>
            <p className="dark">We shall not be held responsible for any content that appears once you click on a link for a third-party site that is outside of our website. We are not responsible for any website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violations of, any third-party rights. By clicking on any linked content, you are agreeing to be bound by these terms and conditions.</p>

            <h5 className="montserrat-regular dark"><b>Applicable law</b></h5>
            <p className="dark">By using this website, you agree that the laws of the county of Yellowstone in the state of Montana without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Energy Laboratories Inc. and you, or its business partners and associates.</p>

            <h5 className="montserrat-regular dark"><b>Disputes</b></h5>
            <p className="dark">Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by state or federal court of the county of Yellowstone in the state of Montana and you consent to exclusive jurisdiction and venue of such courts.</p>

            <h5 className="montserrat-regular dark"><b>Indemnification</b></h5>
            <p className="dark">You agree to Energy Laboratories Inc. and its affiliates and hold Energy Laboratories Inc. against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel. </p>

            <h5 className="montserrat-regular dark"><b>Limitation on liability</b></h5>
            <p className="dark">Energy Laboratories Inc. is not liable for any damages that may occur to you as a result of your misuse of our website.</p>
            <p className="dark">Energy Laboratories Inc. reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through updates to website notice. This Agreement is an understanding between Energy Laboratories Inc. and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</p>
        </div>
    );
};

export default TermsAndConditions;