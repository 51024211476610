import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const SideNav = styled.div`
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: ${({ isNavClosed }) => (isNavClosed ? '3rem' : '100%')};
    font-size: 24px;
    padding-right: 1%;
    transition: 0.5s;
    white-space: nowrap;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`;

const ServiceNav = styled.div`
    cursor: pointer;
    min-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding-left: 1rem;

    &:hover > div {
        background-color: var(--NiceBlue);
    }

    &:hover > a {
        color: var(--NiceBlue);
    }
`;

const Disk = styled.div`
    min-width: 1rem;
    min-height: 1rem;
    background-color: white;
    border-radius: 50%;
    transition: 0.5s;
`;

const SLinks = styled(Link)`
    color: white;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    transition: 0.5s;
    width: ${({ isNavClosed }) => (isNavClosed ? '0' : '100%')};
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InfoBlock = styled.section`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    scroll-margin-top: 3rem;
    overflow: hidden;
    background-image: url(${({ backgroundImageURL }) => backgroundImageURL});

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -1;
        transform: scale(1.05);
        filter: blur(5px);
    }
`;

const Back = styled.div`
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1%;
    width: 60%;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 0.5rem;
`;

const Title = styled.b`
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
`;

const Text = styled.p`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
`;

const AddressLink = styled.a`
    color: white;
    text-decoration: none;

    &:visited,
    &:active {
        color: white;
        text-decoration: none;
    }

    &:hover {
        color: var(--NiceBlue);
        text-decoration: none;
    }
`;

const LocationBlurb = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-column: 1;
`;

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-column: 2;
    gap: 1rem;
`;

export const Locations = () => {
    const [isNavClosed, setIsNavClosed] = useState(true);
    const [locationsData, setLocationsData] = useState(null);

    const fetchLocations = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_APILink + "/api/Locations", {
                method: 'GET'
            });
            const data = await response.json();

            setLocationsData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        initialPageScroll();
        fetchLocations();
    }, []);

    const initialPageScroll = () => {
        const hash = window.location.hash.substring(1);
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start', 
                });
            }
        }
    };

    return (
        <div>
            <SideNav
                isNavClosed={isNavClosed}
                onMouseEnter={() => setIsNavClosed(false)}
                onMouseLeave={() => setIsNavClosed(true)}
            >
                {locationsData && locationsData.map((location) => (
                    <ServiceNav key={location.id}>
                        <Disk />
                        <SLinks
                            to={location.name}
                            smooth
                            duration={100}
                            offset={-100}
                            isNavClosed={isNavClosed}
                        >
                            {location.name}
                        </SLinks>
                    </ServiceNav>
                ))}
            </SideNav>
            <ServicesContainer>
                {locationsData && locationsData.map((location) => (
                    <InfoBlock
                        key={location.id}
                        id={location.name}
                        backgroundImageURL={`data:${location.mimeType};base64,${location.image}`}
                    >
                        <Back>
                            <LocationBlurb>
                                <Title>{location.name}</Title>
                                <Text>{location.description}</Text>
                            </LocationBlurb>
                            <ContactInfo>
                                <span>
                                    <Text><strong>Address:</strong></Text>
                                    <AddressLink href={location.addressURL} target="_blank">
                                        <Text>{location.addressText}</Text>
                                    </AddressLink>
                                </span>
                                <span>
                                    <Text><strong>Numbers:</strong></Text>
                                    <Text>Local: {location.phoneNumber}</Text>
                                    <Text>Fax: {location.faxNumber}</Text>
                                </span>
                                <span>
                                    <Text><strong>Mailing Address:</strong></Text>
                                    <Text>{location.mailingAddress}</Text>
                                </span>
                            </ContactInfo>
                        </Back>
                    </InfoBlock>
                ))}
            </ServicesContainer>
        </div>
    );
};