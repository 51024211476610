import React, { useEffect, useState } from 'react';
import WhatWeDoSquare from './WhatWeDoSquare';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FlexWwd = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%;
    align-items: center;
    margin: 0 auto;
    gap: 5px;
`;

const Square = styled(Link)`
    position: relative;
    width: 24%;
    height: auto;

    @media (max-width: 765px) {
        width: calc(50% - 5px);
    }
`;

const ServiceDisplay = () => {
    const [services, setServices] = useState([]);
    const fetchData = async () => {
        fetch(process.env.REACT_APP_APILink + "/api/ELIServices/mini")
            .then(response => response.json())
            .then(data => {
                setServices(data);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <FlexWwd>
            {services && services.map((service, index) => (
                <Square key={index} to={`/services#${service.title}`}>
                    <WhatWeDoSquare image={`data:${service.mimeType};base64,${service.image}`} text={service.title} />
                </Square>
            ))}
        </FlexWwd>
    );
};

export default ServiceDisplay;