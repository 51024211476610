import React, { useContext } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import CookieBanner from './CookieBanner';
import { ChatContext, ChatProvider } from './Contexts/ChatContext';
import ChatWindow from './ChatWindow';

const Badge = styled.div`
    display: ${props => props.show ? 'block' : 'none'};
    position: relative;
    z-index: 11;
`;

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const FlexGrowDiv = styled.div`
    flex: 1;
`;

export const Layout = ({ children }) => {
    const location = useLocation(); // Get the current location
    const [showCaptchaBadge, setShowCaptchaBadge] = React.useState(false);
    const captchaProtectedPages = ['contact-us', 'request-quote', 'new-order', 'repeat-order'];
    const [cookiesAccepted, setCookiesAccepted] = React.useState(false);

    React.useEffect(() => {
        const path = location.pathname;
        const pathArray = path.split('/');
        const currentPath = pathArray[pathArray.length - 1];
        if (captchaProtectedPages.includes(currentPath)) {
            setShowCaptchaBadge(true);
        } else {
            setShowCaptchaBadge(false); // Hide the badge if the path is not protected
        }
    }, [location]); // Trigger the effect on location change
    
    return (
        <FlexDiv>
            <ChatWindow />
            <NavMenu />
            <FlexGrowDiv>
                {children}
            </FlexGrowDiv>
            <Badge show={showCaptchaBadge} id="captcha_badge"></Badge>
            <CookieBanner cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} />
            <Footer />
        </FlexDiv>
    );
};

Layout.displayName = 'Layout';