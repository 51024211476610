import Home from "./components/Home";
import HelpfulLinks from "./components/HelpfulLinks";
import ContactUs from "./components/ContactUs";
import FAQ from "./components/FAQ";
import { PrivacyPolicy } from "./components/PrivacyPolicy"
import TermsAndConditions from "./components/TermsAndConditions"
import Documents from "./components/Documents"
import { Services } from "./components/Services"
import { Locations } from "./components/Locations"
import { NewOrder, RepeatOrder, NewQuote } from "./components/OrderForms"
import { AboutUs } from "./components/AboutUs"

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/helpful-links',
        element: <HelpfulLinks />
    },
    {
        path: '/faq',
        element: <FAQ />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
    },
    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />
    },
    {
        path: '/documents-certifications',
        element: <Documents />
    },
    {
        path: '/services',
        element: <Services />
    },
    {
        path: '/repeat-order',
        element: <RepeatOrder />
    },
    {
        path: '/new-order',
        element: <NewOrder />
    },
    {
        path: '/request-quote',
        element: <NewQuote />
    },
    {
        path: '/locations',
        element: <Locations />
    },
    {
        path: '/about-us',
        element: <AboutUs />
    }
];

export default AppRoutes;
