import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const rotate_animation = props => css`
    ${rotate360} 1.5s infinite ease-in-out;
`;

const SearchBarContainer = styled.div`
    position: relative;
    flex-grow: 1;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    padding: 0 0.5rem;
    display: flex;
    gap: 0.5rem;
    min-width: 0;

    &:focus-within {
        outline: 2px solid var(--NiceBlue);
    }
`;

const TextInput = styled.input`
    border: none;
    flex-grow: 1;
    min-width: 0;

    &:focus {
        outline: none;
    }
`;

const SearchResultsContainer = styled.div`
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 40vw;
    max-height: 75vh;
    overflow-y: auto;
    width: 100%;
    border-radius: 20px;
    background-color: var(--ZeusBrown); 
    padding: 0.75rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: white;
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 78%);

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4f413d;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        background-color: transparent;
        height: 8px;
    }

    &::-webkit-scrollbar-corner {
        background-color: red;
    }   
`;

const SearchResultLink = styled.a`
    color: white; 
    text-decoration: none;
    padding-left: 1rem;

    &:hover {
        color: #ddd;
    }
`;

const SearchResultHeader = styled.h5`
    margin: 0;
`;

const AccordionItem = styled.div`
    // border-bottom: 1px solid #ccc;
`;

const AccordionHeader = styled.div`
    cursor: pointer;
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    font-style: italic;

    &:hover {
        color: #ddd;
    }
`;

const AccordionContent = styled.div`
    padding-left: 1rem;
    max-height: ${({ isOpen }) => (isOpen ? 'none' : '0')};
    overflow: hidden;
    transition: max-height 0.3s;
`;

const Arrow = styled(FaChevronDown)`
    transition: transform 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

const SearchIcon = styled(FaSearch)`
    cursor: pointer;
    animation: ${({ isRotating }) => (isRotating ? rotate_animation : 'none')};
`;

const OpenInNewIcon = styled(MdOpenInNew)`
    font-size: 1rem;
    color: var(--NiceBlue);
    cursor: pointer;
    margin-left: 0.5rem;
    
    &:hover {
        color: var(--CadmiumOrange);
    }
`;

const DownloadIcon = styled(IoMdDownload)`
    font-size: 1.3rem;
    color: ${({ parentHovered }) => (parentHovered ? 'var(--CadmiumOrange)' : 'var(--NiceBlue)')};
    cursor: pointer;
    margin-left: 0.5rem;

    &:hover {
        color: var(--CadmiumOrange);
    }
`;

const SearchAccordion = ({ header, content, hasEmbed }) => {
    const [open, setOpen] = useState(false);

    return (
        <AccordionItem>
            <AccordionHeader onClick={() => setOpen(!open)}>
                {header} <Arrow isOpen={open} />
            </AccordionHeader>
            <AccordionContent isOpen={open}>
                {content} {hasEmbed &&
                    <a href={"faq"} target="_blank" rel="noreferrer">
                        <OpenInNewIcon />
                    </a>
                }
            </AccordionContent>
        </AccordionItem>
    );
};

const DownloadLink = ({ link }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <SearchResultLink 
        key={link.id} 
        href={`${process.env.REACT_APP_APILink}/api/Links/Content/${link.fileName}`} 
        target="_blank" 
        rel="noreferrer"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
            {link.title}
            <DownloadIcon parentHovered={hovered} />
        </SearchResultLink>
    )
}

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    const [searchResultsLoading, setSearchResultsLoading] = useState(false);
    const inputRef = useRef(null); // Reference to the input element
    const searchBarRef = useRef(null); // Reference to the search bar container

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            inputRef.current.blur(); // Unfocus the input
        } else if (e.key === 'Enter') {
            handleSearch(); // Trigger search logic
        }
    };

    useEffect(() => {
        const eventListener = (e) => {
            if (e.key === '/') {
                e.preventDefault();
                inputRef.current.focus();
            }
        };

        document.addEventListener('keydown', eventListener);

        return () => {
            document.removeEventListener('keydown', eventListener);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchResultsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = async () => {
        if (searchTerm === '') return;
        setSearchResultsLoading(true);
        fetch(`${process.env.REACT_APP_APILink}/api/Search?query=${searchTerm}`)
            .then((response) => response.json())
            .then((data) => {
                setSearchResults(data);
                setSearchResultsLoading(false);
            });
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            setSearchResults(null);
        }
    }

    return (
        <SearchBarContainer ref={searchBarRef}>
            <TextInput
                ref={inputRef}
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onKeyDown={handleKeyDown} // Handle key presses directly on the input
                onChange={handleSearchTermChange}
                onClick={() => setSearchResultsVisible(true)}
            />
            <SearchIcon onClick={handleSearch} isRotating={searchResultsLoading} />
            {searchResultsVisible && searchResults && (
                <SearchResultsContainer>

                    {searchResults.staticSearchResults && searchResults.staticSearchResults.map((result) => {
                        return (
                            <SearchResultLink key={result.id} href={result.url} target="_blank" rel="noreferrer">
                                {result.displayText}
                            </SearchResultLink>
                        )
                    })}


                    {searchResults.links.length > 0 && <SearchResultHeader>Links</SearchResultHeader>}
                    {searchResults.links && searchResults.links.map((link) => {
                        if (link.discriminator === "NavigationLink") {
                            return (
                                <SearchResultLink key={link.id} href={link.url} target="_blank" rel="noreferrer">
                                    {link.title}
                                </SearchResultLink>
                            );
                        } else if (link.discriminator === "ContentLink") {
                            return (
                                <DownloadLink key={link.id} link={link} />
                            );
                        }
                    })}


                    {searchResults.faqs.length > 0 && <SearchResultHeader>FAQ</SearchResultHeader>}
                    {searchResults.faqs && searchResults.faqs.map((faq) => (
                        <SearchAccordion key={faq.id} header={faq.question} content={faq.answer} hasEmbed={faq.embed} />
                    ))}


                    {searchResults.services.length > 0 && <SearchResultHeader>Services</SearchResultHeader>}
                    {searchResults.services && searchResults.services.map((service) => (
                        <SearchResultLink key={service.title} href={`/services#${service.title}`} target="_blank" rel="noreferrer">
                            {service.title}
                        </SearchResultLink>
                    ))}


                    {searchResults.locations.length > 0 && <SearchResultHeader>Locations</SearchResultHeader>}
                    {searchResults.locations && searchResults.locations.map((location) => (
                        <SearchResultLink key={location.id} href={`/locations#${location.name}`} target="_blank" rel="noreferrer">
                            {location.name}
                        </SearchResultLink>
                    ))}


                    {searchResults.documentOuts.length > 0 && <SearchResultHeader>Documents</SearchResultHeader>}
                    {searchResults.documentOuts && searchResults.documentOuts.map((document) => (
                        <SearchResultLink key={document.id} href={`${process.env.REACT_APP_APILink}/api/DocumentCategories/Download/${document.id}/${document.name}`} target="_blank" rel="noreferrer">
                            {document.name}
                        </SearchResultLink>
                    ))}
                    {
                        searchResults.faqs.length == 0 &&
                        searchResults.links.length == 0 &&
                        searchResults.documentOuts.length == 0 &&
                        searchResults.staticSearchResults.length == 0 &&
                        <SearchResultHeader>
                            No results found
                        </SearchResultHeader>
                    }
                </SearchResultsContainer>
            )}
        </SearchBarContainer>
    );
};

export default SearchBar;