import React, { useState } from 'react';

export const CopyToClipboard = ({ textToCopy, displayText }) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <div>
            <p className="fake-link" onClick={handleCopy}>{displayText}</p>
            {showPopup && (
                <div className="popup">
                    {textToCopy} copied to clipboard
                </div>
            )}
        </div>
    );
};